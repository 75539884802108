.Input {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
}

.Slot {
  position: relative;
  width: --token(--space-40);
  height: --token(--space-48);
  text-transform: uppercase;
  border: 1px solid var(--global-inner-contrast-border);
  box-shadow: 0 0 0 1px var(--global-outer-contrast-border);

  &:first-child {
    border-top-left-radius: --token(--radius-16);
    border-bottom-left-radius: --token(--radius-16);
  }

  &:last-child {
    border-top-right-radius: --token(--radius-16);
    border-bottom-right-radius: --token(--radius-16);
  }

  &[data-slot-active='true'] {
    border: 2px solid var(--global-foreground);
  }
}

.CaretContainer {
  position: absolute;
  inset: 0;
}

.Caret {
  width: 2px;
  background: var(--global-foreground);
  max-height: 60%;
  border-radius: 1e5px;
  animation: blink 1.2s ease-out infinite;
}

@keyframes blink {
  0% {
    opacity: 100%;
  }

  50% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}
